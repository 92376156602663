var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-container',[_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" Account ")])]),_c('v-spacer'),_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[(!_vm.editDataStatus)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.editDataStatus = !_vm.editDataStatus}}},[_vm._v(" Change Data ")]):_vm._e(),(_vm.editDataStatus)?_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel('data')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return passes(_vm.updateData)}}},[_vm._v(" Save ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"mt-4 pl-4 pr-4"},[_c('ValidationProvider',{ref:"providerName",attrs:{"vid":"name","name":"Priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors,"required":"","disabled":!_vm.editDataStatus,"data-test":"name-text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerUsername",attrs:{"vid":"username","name":"Priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username","error-messages":errors,"required":"","disabled":!_vm.editDataStatus,"data-test":"username-text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerEmail",attrs:{"name":"Priority","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-1 mb-4",attrs:{"label":"E-mail","error-messages":errors,"required":"","disabled":!_vm.editDataStatus,"data-test":"email-text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)]}}])}),_c('v-divider',{staticClass:"mt-6"}),_c('v-divider',{staticClass:"mb-6"}),_c('ValidationObserver',{ref:"pass",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" Password ")])]),_c('v-spacer'),_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[(!_vm.editPasswordStatus)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.editPasswordStatus = !_vm.editPasswordStatus}}},[_vm._v(" Change Password ")]):_vm._e(),(_vm.editPasswordStatus)?_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel('password')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return passes(_vm.updatePassword)}}},[_vm._v(" Save ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"mt-4 pl-4 pr-4"},[_c('ValidationProvider',{ref:"providerCurrentPassword",attrs:{"name":"Priority","rules":"required","vid":"currentPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Current password","append-icon":_vm.showCurrentPassword? 'mdi-eye': 'mdi-eye-off',"type":_vm.showCurrentPassword ? 'text': 'password',"error-messages":errors,"required":"","disabled":!_vm.editPasswordStatus,"data-test":"password-text"},on:{"click:append":function($event){_vm.showCurrentPassword = !_vm.showCurrentPassword}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerNewPassword",attrs:{"name":"Priority","rules":"required|password|comparePasswords:@currentPassword","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"New password","append-icon":_vm.showNewPassword? 'mdi-eye': 'mdi-eye-off',"type":_vm.showNewPassword ? 'text': 'password',"error-messages":errors,"required":"","disabled":!_vm.editPasswordStatus,"data-test":"newPassword-text"},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerConfirmPassword",attrs:{"rules":"required|confirmed:newPassword","name":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Confirm new password","append-icon":_vm.showConfirmPassword? 'mdi-eye': 'mdi-eye-off',"type":_vm.showConfirmPassword ? 'text': 'password',"error-messages":errors,"required":"","disabled":!_vm.editPasswordStatus,"data-test":"confirmNewPassword-text"},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})]}}],null,true)})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }